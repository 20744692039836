import PropTypes from 'prop-types';
import { SAVE_SYSTEM_MESSAGE, SAVE_SYSTEM_FOOTER } from '../actionTypes';

const initialState = {
    messageBlocks: [{ id: null }, { id: null }],
    messageFooter: {
        id: null,
        tooltip: '',
        health: 'GREEN',
    },
};

/**
 * messages reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const messagesReducer = (state = initialState, action) => {
    if (action.type === SAVE_SYSTEM_MESSAGE) {
        return {
            ...state,
            messageBlocks: state.messageBlocks.map((block, i) => {
                if (i === action.digit) return action.messageBlock;
                return block;
            }),
        };
    }

    if (action.type === SAVE_SYSTEM_FOOTER) {
        return {
            ...state,
            messageFooter: {
                id: action.id,
                tooltip: action.tooltip,
                health: action.health,
            },
        };
    }

    return state;
};

export const messageBlockProptypes = {
    id: PropTypes.number,
    active: PropTypes.bool,
    headline: PropTypes.string,
    text: PropTypes.string,
    messageType: PropTypes.string,
};

export default messagesReducer;
