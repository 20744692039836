import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './CenterTokenForm.scss';
import { addAPIUserDispatch } from '../../redux/actions';
import { replaceText } from '../../helper';

/**
 * Form to create an api access token for this center
 */
class CenterTokenForm extends PureComponent {
    constructor() {
        super();
        this.areaRef = React.createRef();
    }

    addApiUser = event => {
        event.preventDefault();
        addAPIUserDispatch();
    };

    copyToClipboard = event => {
        event.preventDefault();
        this.areaRef.current.select();
        document.execCommand('copy');
        window.alert(replaceText(this.props.texts, 'token.copied'));
    };

    readToken = apiToken => {
        if (apiToken === '') return { exp: '', iat: '' };
        const parse = JSON.parse(atob(apiToken.split('.')[1]));
        const exp = new Date(parse.exp * 1000).toLocaleDateString(
            navigator.language,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        const iat = new Date(parse.iat * 1000).toLocaleDateString(
            navigator.language,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        return { exp, iat };
    };

    render() {
        const apiToken = this.props.apiToken;
        const { exp, iat } = this.readToken(apiToken);
        const userOrgId = `${this.props.currentUserOrganization.id}`;
        const orgId = this.props.match.params.id;

        return (
            <div className="centerTokenForm">
                <h2>{replaceText(this.props.texts, 'token.generate')}</h2>
                <p>{replaceText(this.props.texts, 'token.hint')}</p>
                <form>
                    <div className="centerTokenForm__generate">
                        <button
                            onClick={this.addApiUser}
                            className="btn--primary"
                            disabled={userOrgId !== orgId}>
                            {replaceText(this.props.texts, 'token.generation')}
                        </button>
                        {userOrgId !== orgId && (
                            <span>
                                {replaceText(this.props.texts, 'token.only')}
                            </span>
                        )}
                    </div>
                    <div className="centerTokenForm__token">
                        <textarea
                            ref={this.areaRef}
                            placeholder="generiertes Token"
                            value={apiToken}
                            disabled={userOrgId !== orgId}
                            readOnly></textarea>
                        {apiToken !== '' && (
                            <button onClick={this.copyToClipboard}>
                                {replaceText(this.props.texts, 'token.copy')}
                            </button>
                        )}
                    </div>
                    {apiToken !== '' && (
                        <div className="centerTokenForm__info">
                            <p>
                                <span>
                                    {replaceText(
                                        this.props.texts,
                                        'token.created'
                                    )}
                                </span>{' '}
                                {iat}
                            </p>
                            <p>
                                <span>
                                    {replaceText(
                                        this.props.texts,
                                        'token.valid'
                                    )}
                                </span>{' '}
                                {exp}
                            </p>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

// PropTypes for this Component
CenterTokenForm.propTypes = {
    apiToken: PropTypes.string,
    currentUserOrganization: PropTypes.any,
    match: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        apiToken: state.apiToken,
        currentUserOrganization: state.currentUser.organization,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(CenterTokenForm));
