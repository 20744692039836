import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
    toggleUserSortingOrderDispatch,
    setUserSortingPropertyDispatch,
    setSortedUsersDispatch,
} from '../../redux/actions/users';

import { userProptypes } from '../../redux/reducers/users';

import Caret from '../icons/Caret';
import './UserSorting.scss';
import '../global/Sorting.scss';
import { replaceText } from '../../helper';

/**
 * Table headers for the user list entries contains the possibility to sort the list.
 */
class UserSorting extends PureComponent {
    sortUsers = () => {
        const sortedUsers = this.props.users.sort((a, b) => {
            let valueA = a[this.props.sortingProperty];
            let valueB = b[this.props.sortingProperty];

            if (valueA === null || valueB === null) return 0;
            else if (this.props.sortingProperty === 'dispatchCenter') {
                valueA = a.organization.name.toLowerCase();
                valueB = b.organization.name.toLowerCase();
            } else {
                valueA = valueA.toLowerCase();
                valueB = valueB.toLowerCase();
            }

            if (valueA > valueB)
                return this.props.sortingOrder === 'DESC' ? 1 : -1;
            if (valueA < valueB)
                return this.props.sortingOrder === 'DESC' ? -1 : 1;
            return 0;
        });

        setSortedUsersDispatch([...sortedUsers]);
    };

    clickHandler = property => {
        if (property === this.props.sortingProperty) {
            toggleUserSortingOrderDispatch();
        } else {
            setUserSortingPropertyDispatch(property);
        }
    };

    componentDidMount() {
        this.sortUsers();
    }

    componentDidUpdate() {
        this.sortUsers();
    }

    render() {
        const classes = clsx(
            'userSorting',
            `userSorting--${this.props.sortingOrder}`,
            `userSorting--${this.props.sortingProperty}`
        );

        const currentRole = this.props.currentRole;

        return (
            <div className={classes}>
                <div
                    className="col col--name"
                    onClick={() => {
                        this.clickHandler('name');
                    }}>
                    <span title={'Name'}>
                        {replaceText(this.props.texts, 'name')}
                    </span>{' '}
                    <Caret />
                </div>
                <div
                    className="col col--email"
                    onClick={() => {
                        this.clickHandler('email');
                    }}>
                    <span title={'Passwort'}>
                        {replaceText(this.props.texts, 'email')}
                    </span>{' '}
                    <Caret />
                </div>
                <div className="col col--ipRestriction">
                    <span title={'IP - Restriktion'}>
                        {replaceText(this.props.texts, 'ip.restriction')}
                    </span>{' '}
                </div>
                {currentRole === 'superadmin' && (
                    <div
                        className="col col--dispatchCenter"
                        onClick={() => {
                            this.clickHandler('dispatchCenter');
                        }}>
                        <span title={'Dispatch Center'}>
                            {replaceText(this.props.texts, 'center')}
                        </span>{' '}
                        <Caret />
                    </div>
                )}
                <div
                    className="col col--role"
                    onClick={() => {
                        this.clickHandler('role');
                    }}>
                    <span title={'Rolle'}>
                        {replaceText(this.props.texts, 'role')}
                    </span>{' '}
                    <Caret />
                </div>
                <div className="col col--options"></div>
            </div>
        );
    }
}

// PropTypes for this Component
UserSorting.propTypes = {
    sortingOrder: PropTypes.string,
    sortingProperty: PropTypes.string,
    currentRole: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        sortingOrder: state.userSorting.sortingOrder,
        sortingProperty: state.userSorting.sortingProperty,
        currentRole: state.currentUser.role,
        users: state.users,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(UserSorting);
