import { oauth } from '../OAuth';
import { remapDispatchCenterToAddOn, getAddOnId } from './apiHelper';
import store from '../redux/store';
import { restCall } from './api';

const dispatchCentersAPIUrl = process.env.REACT_APP_DISPATCHCENTERS_ENDPOINT;

/**
 * get dispatch centers
 * @returns {object} response data
 */
export const getDispatchCenterAPI = async () => {
    return await fetchDispatchCenterAPI();
};

/**
 * add a new dispatch center
 * @param {object} center
 * @returns {object} response data
 */
export const addDispatchCenterAPI = async center => {
    return await fetchDispatchCenterAPI({
        method: 'POST',
        body: JSON.stringify({
            ...remapDispatchCenterToAddOn(center),
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * update an existing dispatch center
 * @param {object} center
 * @returns {object} response data
 */
export const updateDispatchCenterAPI = async center => {
    return await fetchDispatchCenterAPI(
        {
            method: center.addOnId ? 'PUT' : 'POST',
            body: JSON.stringify({
                ...remapDispatchCenterToAddOn(center),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        center.addOnId
    );
};

/**
 * delete an existing dispatch center for a given id
 * @param {string} orgId
 * @returns {object} response data
 */
export const deleteDispatchCenterAPI = async orgId => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
        method: 'DELETE',
    };

    const addOnId = getAddOnId(store.getState().dispatchCenters.centers, orgId);

    if (!addOnId) return;
    await fetch(`${dispatchCentersAPIUrl}/${addOnId}`, params);
};

/**
 * query the dispatch center api
 * @param {object} params
 * @param {string} id
 * @returns {object} response data
 */
export const fetchDispatchCenterAPI = async (params = {}, id = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = dispatchCentersAPIUrl;
    if (id) {
        url = `${url}/${id}`;
    }

    const result = await restCall(url, mergedParams);

    if (!result.page) {
        if (result._embedded) return result._embedded.dispatchCenters;
        return result;
    }

    // get all results if there are more pages
    const limit = result.page.totalElements;
    const dispatchCentersResults = await restCall(
        `${url}?limit=${limit}`,
        mergedParams
    );

    return dispatchCentersResults._embedded.dispatchCenters;
};
