import React from 'react';

function Toggle() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <path id="Pfad_3076" d="m60,51.46c7.71,0,13.95-6.25,13.95-13.95,0-7.71-6.25-13.95-13.95-13.95-7.71,0-13.95,6.25-13.95,13.95h0c0,7.71,6.25,13.96,13.95,13.96h0"/>
            <path id="Pfad_3077" d="m70,56.46h-20c-8.27,0-14.98,6.71-15,14.98v23.33c0,.92.75,1.67,1.67,1.67h46.67c.92,0,1.67-.75,1.67-1.67h0v-23.33c0-8.28-6.72-14.99-15-15"/>
        </svg>
    );
}

export default Toggle;
