import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import clsx from 'clsx';

import './Navigation.scss';
import { replaceText } from '../../helper';

/**
 * Application navigation depending on the user role
 */

class Navigation extends PureComponent {
    render() {
        return (
            <nav className="navigation">
                <Link
                    className={clsx('btn btn--primary', {
                        'btn--active': this.props.match.path === '/user',
                    })}
                    to="/user">
                    {replaceText(this.props.texts, 'user')}
                </Link>
                <Link
                    className={clsx('btn btn--primary', {
                        'btn--active': this.props.match.path === '/center',
                    })}
                    to="/center">
                    {replaceText(this.props.texts, 'center')}
                </Link>
                {this.props.currentRole === 'superadmin' && (
                    <Link
                        className={clsx('btn btn--primary', {
                            'btn--active':
                                this.props.match.path === '/messages',
                        })}
                        to="/messages">
                        {replaceText(this.props.texts, 'messages')}
                    </Link>
                )}
            </nav>
        );
    }
}

// PropTypes for this Component
Navigation.propTypes = {
    currentRole: PropTypes.string,
    match: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentRole: state.currentUser.role,
        texts: state.texts.texts,
    };
};

export default withRouter(connect(mapStateToProps)(Navigation));
