import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';

import './CenterChatBlock.scss';

/**
 * Single chat block containing the drag & drop handlers
 */

const CenterChatBlock = ({ id, text, index, moveCard, deleteText, texts }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'centerChatBlock',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'centerChatBlock', id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    const deleteEntry = () => {
        deleteText(text);
    };

    return (
        <div className="centerChatBlock" ref={ref} style={{ opacity }}>
            <span>{text}</span>
            <span className="centerChatBlock__delete" onClick={deleteEntry}>
                {replaceText(texts, 'action.delete.entry')}
            </span>
        </div>
    );
};

CenterChatBlock.propTypes = {
    text: PropTypes.string,
    deleteText: PropTypes.func,
    dragStartHandler: PropTypes.func,
    id: PropTypes.number,
    index: PropTypes.number,
    moveCard: PropTypes.func,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(CenterChatBlock);
