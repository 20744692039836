import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { userProptypes } from '../../redux/reducers/users';

import Header from '../global/Header';
import Headline from '../global/Headline';
import AddUser from '../icons/AddUser';

import UserFilter from './UserFilter';
import UserPagination from './UserPagination';
import UserSorting from './UserSorting';
import UserList from './UserList';
import {
    resetUserFiltersDispatch,
    closeUserFilterDispatch,
} from '../../redux/actions/users';
import { replaceText } from '../../helper';

/**
 * Shows the current user list, paginated and sorted.
 *
 * @component Header - Application header with logo and nav
 * @component Headline - Simple headline
 * @component UserFilter - Filter for the user list
 * @component UserSorting - Columnheaders and sorting possibilities
 * @component UserList - Actual user list
 * @component UserPagination - User list pagination
 */
const UserListView = ({ history, texts }) => {
    useEffect(() => {
        resetUserFiltersDispatch();
        closeUserFilterDispatch();
    }, []);

    const createNewUser = () => {
        history.push(`/new/user`);
    };

    return (
        <div>
            <Header showLogout={true} />
            <div className="container">
                <Headline headline={replaceText(texts, 'user')}>
                    <button
                        className="btn btn--primary"
                        onClick={createNewUser}>
                        <AddUser />
                        {replaceText(texts, 'action.newuser')}
                    </button>
                </Headline>
                <UserFilter />
                <UserSorting />
                <UserList />
                <UserPagination />
            </div>
        </div>
    );
};

// PropTypes for this Component
UserListView.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    history: PropTypes.any,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        users: state.users,
        texts: state.texts.texts,
    };
};

// Connect Props  to Component
export default withRouter(connect(mapStateToProps)(UserListView));
