import { oauth } from '../OAuth';
import { restCall } from './api';

const imageUploadAPIUrl = process.env.REACT_APP_IMAGE_UPLOAD_ENDPOINT;
const imageAPIUrl = process.env.REACT_APP_IMAGE_ENDPOINT;

export const uploadFile = async ({ file, centerId = null }) => {
    const formData = new FormData();

    formData.append('file', file);

    if (centerId) {
        formData.append('dispatchCenterId', `${centerId}`);
    }

    const mergedParams = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    return restCall(imageUploadAPIUrl, mergedParams, false);
};

export const getImage = async id => {
    const mergedParams = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    let url = imageAPIUrl;

    if (id) {
        url = `${imageAPIUrl}?dispatchCenterId=${id}`;
    }

    return restCall(url, mergedParams, false);
};

export const deleteImage = async id => {
    const mergedParams = {
        method: 'DELETE',
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    };

    let url = imageAPIUrl;

    if (id) {
        url = `${imageAPIUrl}?dispatchCenterId=${id}`;
    }

    return restCall(url, mergedParams, false);
};
