import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './CenterFeatures.scss';
import { withRouter } from 'react-router-dom';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';
import { defaultFeatures } from '../../config';
import ToggleSwitch from '../global/ToggleSwitch';
import {
    addApplicationConfigDispatch,
    setCurrentInviteConfigDispatch,
    setCurrentSessionReportDispatch,
    updateApplicationConfigDispatch,
} from '../../redux/actions';
import { centerProptype } from '../../redux/reducers/dispatchCenters';
import { addOrRemove, replaceText } from '../../helper';
import { postConferenceConfig } from '../../api/conferenceAPI';
import { postSessionReportConfig } from '../../api/sessionReportAPI';

/**
 * Toggles to manage the features available for each center
 *
 * @component ToggleSwitch - simple toggle switch with on/off states
 */

const CenterFeatures = ({
    applicationConfigs,
    currentCenter,
    texts,
    currentInviteConfig,
    currentSessionReportConfig,
}) => {
    let enabledFeatures = [];

    const currentConfig = applicationConfigs.find(
        config => config.dispatchCenterId === currentCenter.addOnId
    );

    if (currentConfig) {
        enabledFeatures = currentConfig.enabledFeatures;
    }

    useEffect(() => {
        if (currentConfig) {
            currentConfig.enabledFeatures.forEach(feature => {
                // If TEILEN (invite) feature is activated, a config must exist to be able to send emails
                if (feature === 'INVITE') {
                    if (!currentInviteConfig.id) {
                        const data = {
                            addOnId: currentConfig.dispatchCenterId,
                            senderName: '',
                            subject: 'Invite Session',
                            emailText:
                                'Please follow the link to join the session',
                            conferenceEnd: ''.toString('markdown'),
                        };
                        postConferenceConfig(data).then(() => {
                            setCurrentInviteConfigDispatch(
                                currentConfig.dispatchCenterId
                            );
                        });
                    }
                }
                // If session report feature is activated, create initial config
                if (feature === 'CASE_DOWNLOAD') {
                    if (!currentSessionReportConfig.id) {
                        const data = {
                            addOnId: currentConfig.dispatchCenterId,
                            hideUserName: false,
                            hideTargetNumber: false,
                            hideLocation: false,
                            hidePhotos: false,
                            hideChat: false,
                            hideLog: false,
                        };
                        postSessionReportConfig(data).then(() => {
                            setCurrentSessionReportDispatch(
                                currentConfig.dispatchCenterId
                            );
                        });
                    }
                }
            });
        }
    }, [currentConfig, currentInviteConfig.id, currentSessionReportConfig.id]);

    return (
        <div className="centerFeatures">
            <h2>{replaceText(texts, 'features')}</h2>
            <p>{replaceText(texts, 'features.info')}</p>
            {defaultFeatures.map(feature => {
                const isActive = enabledFeatures.indexOf(feature) !== -1;

                // Request made to remove MULTILANGUAGE from feature list. This will happen automatically
                // upon user login. Display of this feature as a toggle is no longer necessary.
                if (feature !== 'MULTILANGUAGE') {
                    return (
                        <div
                            className="centerFeatures__toggleWrapper"
                            key={feature}>
                            <ToggleSwitch
                                isActive={isActive}
                                label={replaceText(texts, `feature.${feature}`)}
                                id={feature}
                                changeHandler={() => {
                                    changeFeature({
                                        feature,
                                        enabledFeatures,
                                        currentConfig,
                                        currentCenter,
                                    });
                                }}
                            />
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

// PropTypes for this Component
CenterFeatures.propTypes = {
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    currentCenter: PropTypes.shape(centerProptype),
    texts: PropTypes.object,
    currentInviteConfig: PropTypes.object,
    currentSessionReportConfig: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = (state, ownProps) => {
    return {
        applicationConfigs: state.applicationConfigs,
        currentCenter: state.dispatchCenters.centers.find(
            center => center.id === Number(ownProps.match.params.id)
        ),
        texts: state.texts.texts,
        currentInviteConfig: state.currentInviteConfig,
        currentSessionReportConfig: state.currentSessionReportConfig,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(CenterFeatures));

export const changeFeature = ({
    feature,
    enabledFeatures,
    currentConfig,
    currentCenter,
}) => {
    const features = addOrRemove(enabledFeatures, feature);
    if (currentConfig) {
        updateApplicationConfigDispatch({
            features,
            currentCenter,
            currentConfig,
        });
    } else {
        addApplicationConfigDispatch({ features, currentCenter });
    }
};
