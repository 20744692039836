import logoLink from '../resources/westnetz.svg';

export const cssVariables = {
    active: '#89BA17',
    inactive: '#CCC',

    primary_light: '#E1EEC4',
    primary: '#89BA17',
    primary_dark: '#7ba715',
    text_on_primary: '#fff',
    primary_transparent: '#e6f2d8',

    secondary: '#F59B00',
    secondary_light: '#f8b94d',
    text_on_secondary: 'white',
    secondary_transparent: '#F7F7F7',

    font_color: 'black',
    link_color: '#89BA17',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#89BA17',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#F59B00',    

    dropdown_background: '#E1EEC4',

};

export const logo = logoLink;
