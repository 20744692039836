import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { replaceText } from '../../helper';
import RichTextEditor from 'react-rte';
import clsx from 'clsx';
import { centerProptype } from '../../redux/reducers/dispatchCenters';
import { RTE_TOOLBAR } from '../../config';
import { applicationProptype } from '../../redux/reducers/applicationConfigs';
import './CenterConferenceForm.scss';
import { withRouter } from 'react-router-dom';
import { UploadFormConference } from './UploadFormConference';
import {
    getEmailConfig,
    patchConferenceConfig,
    patchEmailConfig,
    postEmailConfig,
} from '../../api/conferenceAPI';
import {
    getInviteConfigsDispatch,
    setCurrentInviteConfigDispatch,
} from '../../redux/actions';

const CenterConferenceForm = ({
    centers,
    match,
    history,
    applicationConfigs,
    texts,
    currentInviteConfig,
}) => {
    const senderNameRef = useRef(null);
    const subjectRef = useRef(null);
    const emailRef = useRef('');

    const [error, setError] = useState({});
    const [senderName, setSenderName] = useState('');
    const [subject, setSubject] = useState('');
    const [emailText, setEmailText] = useState('');
    const [addOnId, setAddOnId] = useState(null);
    const [inviteConfigId, setInviteConfigId] = useState('');
    const [conferenceEnd, setEnd] = useState(RichTextEditor.createEmptyValue());
    const [emailConfigId, setEmailConfigId] = useState('');
    const id = parseInt(match.params.id);

    const validateForm = () => {
        let hasFormError = false;
        const formError = {
            senderName: false,
            subject: false,
            emailText: false,
            conferenceEnd: false,
        };

        if (
            !senderName ||
            senderName === null ||
            senderName === '' ||
            senderName.length > 50
        ) {
            hasFormError = true;
            formError.senderName = true;
        } else {
            formError.senderName = false;
        }

        if (
            !subject ||
            subject === null ||
            subject.length === 0 ||
            subject.length > 50
        ) {
            hasFormError = true;
            formError.subject = true;
        } else {
            formError.subject = false;
        }

        if (/[/\\:<>]/g.test(emailText)) {
            hasFormError = true;
            formError.emailText = true;
        } else {
            formError.emailText = false;
        }

        if (conferenceEnd.toString('markdown').length > 1000) {
            hasFormError = true;
            formError.conferenceEnd = true;
        }

        if (hasFormError) {
            setError({
                ...error,
                ...formError,
            });
        } else {
            setError({});
        }

        return hasFormError;
    };

    const validateEmailText = () => {
        let hasFormError = false;
        const formError = {
            emailText: false,
        };

        if (/[/\\:<>]/g.test(emailText)) {
            hasFormError = true;
            formError.emailText = true;
        } else {
            formError.emailText = false;
        }

        if (hasFormError) {
            setError({
                ...error,
                ...formError,
            });
        } else {
            setError({});
        }

        return hasFormError;
    };

    const save = async (e, id) => {
        e.preventDefault();

        if (validateForm()) {
            return;
        }

        try {
            if (typeof id === 'number' && !Number.isNaN(id)) {
                const center = centers.filter(center => center.id === id)[0];
                const addOnId = center.addOnId;

                if (inviteConfigId) {
                    const data = {
                        inviteConfigId,
                        addOnId,
                        senderName,
                        subject,
                        emailText,
                        conferenceEnd: conferenceEnd.toString('markdown'),
                    };
                    await patchConferenceConfig(data);
                }

                if (emailConfigId) {
                    patchEmailConfig(emailConfigId, addOnId, senderName);
                } else {
                    postEmailConfig(addOnId, senderName);
                }
            }

            history.push(`/center`);
        } catch (error) {
            console.error(error);
        }
    };

    const updateInput = () => {
        setSenderName(senderNameRef.current.value);
        setSubject(subjectRef.current.value);
        setEmailText(emailRef.current.value);
    };

    const updateInputRichText = value => {
        if (value) {
            setEnd(value);
        }
    };

    useEffect(() => {
        if (id) {
            const center = centers.filter(center => center.id === id)[0];
            const addOnId = center.addOnId;

            const loadInviteConfig = async id => {
                if (currentInviteConfig && currentInviteConfig.id) {
                    if (center.addOnId) setAddOnId(center.addOnId);
                    if (currentInviteConfig.id)
                        setInviteConfigId(currentInviteConfig.id);
                    if (currentInviteConfig.messageSender)
                        setSenderName(currentInviteConfig.messageSender);
                    if (currentInviteConfig.messageSubject)
                        setSubject(currentInviteConfig.messageSubject);
                    if (currentInviteConfig.messageText)
                        setEmailText(currentInviteConfig.messageText);
                    if (currentInviteConfig.goodbyeText)
                        setEnd(
                            currentInviteConfig.goodbyeText
                                ? RichTextEditor.createValueFromString(
                                      currentInviteConfig.goodbyeText,
                                      'markdown'
                                  )
                                : RichTextEditor.createEmptyValue()
                        );
                }
                setCurrentInviteConfigDispatch(addOnId);
                getInviteConfigsDispatch();
            };

            loadInviteConfig(addOnId);

            const loadEmailConfig = async () => {
                try {
                    const emailConfig = await getEmailConfig(addOnId);
                    if (emailConfig.id) {
                        setEmailConfigId(emailConfig.id);
                    }
                } catch {
                    console.log('Problem with fetching email config');
                }
            };

            loadEmailConfig(addOnId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isNew = typeof id !== 'number' || Number.isNaN(id);

    let logoUploadFeatureEnabled = false;

    if (!isNew && addOnId) {
        const currentConfig = applicationConfigs.filter(
            config => config.dispatchCenterId === addOnId
        )[0];
        if (currentConfig) {
            logoUploadFeatureEnabled =
                currentConfig.enabledFeatures.indexOf('LOGO_UPLOAD') !== -1;
        }
    }

    return (
        <form
            autoComplete="off"
            className="centerForm"
            onChange={updateInput}
            onSubmit={e => save(e, id)}>
            <h2>{replaceText(texts, 'center.share')}</h2>
            <div className={clsx({ 'has-error': error.senderName })}>
                <label htmlFor="sender">
                    <h3>{replaceText(texts, 'center.share.sender')}</h3>
                </label>
                <div className="centerForm__row">
                    <input
                        id="sender"
                        type="text"
                        ref={senderNameRef}
                        value={senderName}
                        onChange={() => {}}
                        maxLength={50}
                    />
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 50)}
                        </span>
                        {replaceText(texts, 'name.info')}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'name.error')}
                </span>
            </div>
            <div className={clsx({ 'has-error': error.subject })}>
                <label htmlFor="subject">
                    <h3>{replaceText(texts, 'email.subject')}</h3>
                </label>
                <div className="centerForm__row">
                    <input
                        id="subject"
                        type="text"
                        ref={subjectRef}
                        value={subject}
                        onChange={() => {}}
                        maxLength={50}
                    />
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 50)}
                        </span>
                        {replaceText(texts, 'subject.info')}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'subject.error')}
                </span>
            </div>
            <div className={clsx({ 'has-error': error.emailText })}>
                <label htmlFor="email">
                    <h3>{replaceText(texts, 'email.text')}</h3>
                </label>
                <div className="centerForm__row">
                    <textarea
                        id="email"
                        type="text"
                        ref={emailRef}
                        placeholder={replaceText(texts, 'email.placeholder')}
                        value={emailText}
                        onChange={() => {}}
                        onKeyUp={validateEmailText}
                        maxLength={255}></textarea>{' '}
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 255)}
                        </span>
                        {replaceText(texts, 'email.info')}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'email.text.error')}
                </span>
            </div>
            {logoUploadFeatureEnabled && (
                <div>
                    <h3>{replaceText(texts, 'logo')}</h3>
                    <div className="centerForm__row">
                        <UploadFormConference />
                        <span>
                            <span className="hint">
                                {replaceText(texts, 'max.size', 2000)}
                            </span>

                            {replaceText(texts, 'logo.hint')}
                        </span>
                    </div>
                </div>
            )}
            <div className={clsx({ 'has-error': error.conferenceEnd })}>
                <label htmlFor="conferenceEnd">
                    <h3>{replaceText(texts, 'sessionend')}</h3>
                </label>
                <div className="centerForm__row">
                    <RichTextEditor
                        toolbarConfig={RTE_TOOLBAR}
                        id="conferenceEnd"
                        readOnly={false}
                        value={
                            conferenceEnd || RichTextEditor.createEmptyValue()
                        }
                        placeholder={replaceText(
                            texts,
                            'sessionend.placeholder'
                        )}
                        onChange={updateInputRichText}></RichTextEditor>{' '}
                    <span>
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 1000)}
                        </span>

                        {replaceText(texts, 'sessionend.hint')}
                    </span>
                </div>
                <span className="error-message">
                    {replaceText(texts, 'sessionend.error')}
                </span>
            </div>
            <div>
                <button type="submit" className="btn btn--primary">
                    {isNew
                        ? replaceText(texts, 'action.newcenter')
                        : replaceText(texts, 'action.save.changes')}
                </button>
            </div>
        </form>
    );
};

CenterConferenceForm.propTypes = {
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    match: PropTypes.any,
    history: PropTypes.any,
    applicationConfigs: PropTypes.arrayOf(PropTypes.shape(applicationProptype)),
    texts: PropTypes.object,
    currentInviteConfig: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        centers: state.dispatchCenters.centers,
        applicationConfigs: state.applicationConfigs,
        texts: state.texts.texts,
        currentInviteConfig: state.currentInviteConfig,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(CenterConferenceForm));
