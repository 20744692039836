import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { deleteImage, getImage } from '../../api/imageUpload';
import Delete from '../icons/Delete';
import './CenterImage.scss';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';

const CenterImage = ({ id = null, texts }) => {
    const [logoSrc, setLogoSrc] = useState();
    // run once
    useEffect(() => {
        getCurrentImage(id);
    }, [id]);

    const getCurrentImage = async id => {
        const image = await getImage(id);
        if (image) {
            const imageData = await image.blob();
            setLogoSrc(URL.createObjectURL(imageData));
        }

        return true;
    };

    const deleteCurrentImage = async () => {
        await deleteImage(id);
        setLogoSrc();
    };

    return (
        <div className="center-image">
            <span>{replaceText(texts, 'logo.current')}</span>
            <span>
                {logoSrc ? (
                    <>
                        <span
                            className="center-image__delete"
                            onClick={deleteCurrentImage}>
                            <Delete /> {replaceText(texts, 'action.delete')}
                        </span>
                        <img src={logoSrc} alt="dispatch center" />
                    </>
                ) : (
                    <span className="center-image__no">
                        {replaceText(texts, 'logo.none')}
                    </span>
                )}
            </span>
        </div>
    );
};

// PropTypes for this Component
CenterImage.propTypes = {
    id: PropTypes.number,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(CenterImage);
