import { oauth } from '../OAuth';
import { restCall } from './api';

const dispatchCentersEndpoint = process.env.REACT_APP_DISPATCHCENTERS_ENDPOINT;
const sessionReportConfigEndpoint =
    process.env.REACT_APP_SESSION_REPORT_CONFIG_ENDPOINT;
const getSessionReportConfigAPI =
    process.env.REACT_APP_SESSION_REPORT_CONFIG_DISPATCH_CENTER;

// DISPATCHER ONLY

/**
 * add all details related to session report config
 * @returns {object} response data
 */
export const postSessionReportConfig = async ({
    addOnId,
    hideUserName,
    hideTargetNumber,
    hideLocation,
    hidePhotos,
    hideChat,
    hideLog,
}) => {
    const dispatchCenterId = `${dispatchCentersEndpoint}/${addOnId}`;
    let url = sessionReportConfigEndpoint;

    const mergedParams = {
        body: JSON.stringify({
            dispatchCenter: dispatchCenterId,
            hideChat,
            hideLocation,
            hideLog,
            hidePhotos,
            hideTargetNumber,
            hideUserName,
        }),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};
/**
 * update session report config entries
 * @returns {object} response data
 */
export const patchSessionReportConfig = async (
    sessionReportConfigId,
    entry,
    state
) => {
    let url = `${sessionReportConfigEndpoint}/${sessionReportConfigId}`;
    let entryObj = {};
    entryObj[entry] = state;

    const mergedParams = {
        body: JSON.stringify(entryObj),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        withCredentials: true,
    };

    return restCall(url, mergedParams);
};

/**
 * get all details related to dispatch center-centric session report config
 * @returns {object} response data
 */
export const getSessionReportConfig = async centerId => {
    const dispatchCenterConfigURL = `${getSessionReportConfigAPI}/${centerId}`;

    const res = await restCall(dispatchCenterConfigURL, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).catch(e => {
        console.log('getInviteConfig', e);
    });

    return { ...res };
};

/**
 * delete specified session report config via center addOnId
 * @returns {object} response data
 */
export const deleteSessionReportConfig = async centerId => {
    const sessionReportConfig = await getSessionReportConfig(centerId);

    if (sessionReportConfig && sessionReportConfig.id) {
        const params = {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${oauth.bearerToken}`,
            },
            method: 'DELETE',
        };

        await fetch(
            `${sessionReportConfigEndpoint}/${sessionReportConfig.id}`,
            params
        );
    }
};

export const getAllSessionReportConfigs = async () => {
    const res = await restCall(sessionReportConfigEndpoint, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    }).catch(e => {
        console.log('getSessionReportConfig', e);
    });

    if (res) {
        if (res._embedded) {
            const configs = res._embedded.reportConfigurations;
            return configs;
        }
    }

    return {};
};
