import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Header from '../global/Header';
import Headline from '../global/Headline';
import MessageBlock from './MessageBlock';
import MessageFooter from './MessageFooter';

import './Messages.scss';
import {
    getSystemFooterDispatch,
    getSystemMessageDispatch,
} from '../../redux/actions';
import { replaceText } from '../../helper';
import { connect } from 'react-redux';

/**
 * Message forms for the frontend: for the message blocks and the footer
 *
 * @component Header - application header
 * @component Headline - simple headline
 * @component MessageBlock - message block form
 * @component MessageFooter - message footer form
 */
class Messages extends PureComponent {
    state = {
        loaded: false,
    };

    async componentDidMount() {
        await getSystemFooterDispatch();
        await getSystemMessageDispatch();

        this.setState({ loaded: true });
    }

    render() {
        return (
            <div className="messages">
                <Header showLogout={true} />
                {this.state.loaded && (
                    <div className="container">
                        <Headline
                            headline={replaceText(
                                this.props.texts,
                                'system.messages'
                            )}
                        />
                        <Headline small={true} headline="Text Block 1" />
                        <div className="messages__container">
                            <MessageBlock elementDigit={0} />
                        </div>
                        <Headline small={true} headline="Text Block 2" />
                        <div className="messages__container">
                            <MessageBlock elementDigit={1} />
                        </div>
                        <Headline small={true} headline="Footer" />
                        <div className="messages__container">
                            <MessageFooter />
                        </div>
                    </div>
                )}
                {!this.state.loaded && (
                    <div className="container">
                        {replaceText(this.props.texts, 'loading')}
                    </div>
                )}
            </div>
        );
    }
}

Messages.propTypes = {
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return { texts: state.texts.texts };
};

// Connect Props to Component
export default connect(mapStateToProps)(Messages);
