import {
    RESET_CURRENT_SESSION_REPORT_CONFIG,
    SET_CURRENT_SESSION_REPORT_CONFIG,
} from '../actionTypes';

export const initialState = {
    id: null,
    hideUserName: false,
    hideTargetNumber: false,
    hideLocation: false,
    hidePhotos: false,
    hideChat: false,
    hideLog: false,
    _links: {},
};

/**
 * current session report config reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const currentSessionReportConfigReducer = (state = initialState, action) => {
    if (action.type === SET_CURRENT_SESSION_REPORT_CONFIG) {
        return {
            ...state,
            ...action.currentSessionReportConfig,
        };
    }

    if (action.type === RESET_CURRENT_SESSION_REPORT_CONFIG) {
        return initialState;
    }

    return state;
};

export default currentSessionReportConfigReducer;
