import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../global/Header';
import Headline from '../global/Headline';
import AddCenter from '../icons/AddCenter';
import CentersSorting from './CentersSorting';
import CentersPagination from './CentersPagination';
import CentersFilter from './CentersFilter';
import CentersList from './CentersList';
import {
    resetCenterFiltersDispatch,
    closeCenterFilterDispatch,
} from '../../redux/actions/dispatchCenters';
import { replaceText } from '../../helper';
import {
    resetCurrentInviteConfigDispatch,
    resetCurrentSessionReportDispatch,
} from '../../redux/actions';

/**
 * Shows the current center list, paginated and sorted.
 *
 * @component Header - Application header with logo and nav
 * @component Headline - Simple headline
 * @component CenterFilter - Filter for the center list
 * @component CenterSorting - Columnheaders and sorting possibilities
 * @component CenterList - Actual center list
 * @component CenterPagination - center list pagination
 */
class CentersListView extends PureComponent {
    componentDidMount() {
        resetCenterFiltersDispatch();
        closeCenterFilterDispatch();
        resetCurrentInviteConfigDispatch();
        resetCurrentSessionReportDispatch();
    }

    createNewCenter = () => {
        this.props.history.push(`/new/center`);
    };

    render() {
        return (
            <div>
                <Header showLogout={true} />
                <div className="container">
                    <Headline headline="Dispatch Center">
                        {this.props.currentUserRole === 'superadmin' && (
                            <button
                                className="btn btn--primary"
                                onClick={this.createNewCenter}>
                                <AddCenter />
                                {replaceText(
                                    this.props.texts,
                                    'action.newcenter'
                                )}
                            </button>
                        )}
                    </Headline>
                    <CentersFilter />
                    <CentersSorting />
                    <CentersList />
                    <CentersPagination />
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
CentersListView.propTypes = {
    history: PropTypes.any,
    currentUserRole: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentUserRole: state.currentUser.role,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default withRouter(connect(mapStateToProps)(CentersListView));
