import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
    setSortedDispatchCentersDispatch,
    setDispatchCenterSortingPropertyDispatch,
    toggleDispatchCenterSortingOrderDispatch,
} from '../../redux/actions/dispatchCenters';

import { centerProptype } from '../../redux/reducers/dispatchCenters';

import Caret from '../icons/Caret';
import './CentersSorting.scss';
import '../global/Sorting.scss';
import { replaceText } from '../../helper';

/**
 * Table headers for the center list entries contains the possibility to sort the list.
 */
class CentersSorting extends PureComponent {
    sortCenters = () => {
        const sortedCenters = this.props.centers.sort((a, b) => {
            let valueA = a[this.props.sortingProperty];
            let valueB = b[this.props.sortingProperty];
            if (valueA > valueB)
                return this.props.sortingOrder === 'DESC' ? 1 : -1;
            if (valueA < valueB)
                return this.props.sortingOrder === 'DESC' ? -1 : 1;
            return 0;
        });

        setSortedDispatchCentersDispatch([...sortedCenters]);
    };

    clickHandler = property => {
        if (property === this.props.sortingProperty) {
            toggleDispatchCenterSortingOrderDispatch();
        } else {
            setDispatchCenterSortingPropertyDispatch(property);
        }
    };

    componentDidMount() {
        this.sortCenters();
    }

    componentDidUpdate() {
        this.sortCenters();
    }

    render() {
        const classes = clsx(
            'centersSorting',
            `centersSorting--${this.props.sortingOrder}`,
            `centersSorting--${this.props.sortingProperty}`
        );

        // const currentRole = this.props.currentRole;

        return (
            <div className={classes}>
                <div
                    className="col col--name"
                    onClick={() => {
                        this.clickHandler('name');
                    }}>
                    <span title={replaceText(this.props.texts, 'name')}>
                        {replaceText(this.props.texts, 'name')}
                    </span>{' '}
                    <Caret />
                </div>
                <div className="col col--ip">
                    <span
                        title={replaceText(this.props.texts, 'ip.restriction')}>
                        {replaceText(this.props.texts, 'ip.restriction')}
                    </span>
                </div>
                <div className="col col--sms">
                    <span title={replaceText(this.props.texts, 'sms.text')}>
                        {replaceText(this.props.texts, 'sms.text')}
                    </span>
                </div>
                <div className="col col--end">
                    <span
                        title={replaceText(
                            this.props.texts,
                            'sessionend.text'
                        )}>
                        {replaceText(this.props.texts, 'sessionend.text')}
                    </span>
                </div>
                <div className="col col--chatBlocks">
                    <span
                        title={replaceText(this.props.texts, 'blocks.simple')}>
                        {replaceText(this.props.texts, 'blocks.simple')}
                    </span>
                </div>
                <div className="col col--options"></div>
            </div>
        );
    }
}

// PropTypes for this Component
CentersSorting.propTypes = {
    sortingOrder: PropTypes.string,
    sortingProperty: PropTypes.string,
    currentRole: PropTypes.string,
    centers: PropTypes.arrayOf(PropTypes.shape(centerProptype)),
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        sortingOrder: state.centersSorting.sortingOrder,
        sortingProperty: state.centersSorting.sortingProperty,
        currentRole: state.currentUser.role,
        centers: state.dispatchCenters.centers,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default connect(mapStateToProps)(CentersSorting);
