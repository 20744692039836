import { combineReducers } from 'redux';

import currentUser from './currentUser';

import userPagination from './userPagination';
import userFilters from './userFilters';
import users from './users';
import userSorting from './userSorting';

import dispatchCenters from './dispatchCenters';
import centersSorting from './dispatchCentersSorting';
import centersPagination from './dispatchCentersPagination';
import centersFilters from './dispatchCentersFilters';

import messages from './messages';

import scopes from './scopes';

import applications from './applications';

import applicationConfigs from './applicationConfigs';

import currentInviteConfig from './currentInviteConfig';

import inviteConfigs from './inviteConfigs';

import currentSessionReportConfig from './currentSessionReportConfig';

import sessionReportConfigs from './sessionReportConfigs';

import organigramConfigs from './organigramConfigs';

import currentOrganigramConfig from './currentOrganigramConfig.js';

import apiToken from './apiToken';

import texts from './texts';

/**
 * root reducer with combined reducers
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

export default combineReducers({
    currentUser,
    userPagination,
    userFilters,
    users,
    userSorting,
    dispatchCenters,
    centersSorting,
    centersPagination,
    centersFilters,
    messages,
    scopes,
    applications,
    applicationConfigs,
    currentInviteConfig,
    inviteConfigs,
    currentSessionReportConfig,
    sessionReportConfigs,
    currentOrganigramConfig,
    organigramConfigs,
    apiToken,
    texts,
});
