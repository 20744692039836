import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { SUPPORTED_LANGUAGES } from '../../config';
import { replaceText } from '../../helper';

import Italy from '../icons/Italy';
import Germany from '../icons/Germany';
import France from '../icons/France';
import UK from '../icons/UK';

import './LanguageSelect.scss';
import Dropdown from './Dropdown';
import { Tick } from '../icons/Tick';

const LanguageSelect = ({ language, texts, selectLanguageHandler }) => {
    const handleSelect = async object => {
        selectLanguageHandler(object.label);
    };

    const getFlag = lang => {
        switch (lang) {
            case 'de':
                return (
                    <React.Fragment>
                        <Germany />
                        <span className="languageSelect__language">
                            {replaceText(texts, 'lang.de')}
                        </span>
                    </React.Fragment>
                );
            case 'it':
                return (
                    <React.Fragment>
                        <Italy />
                        <span className="languageSelect__language">
                            {replaceText(texts, 'lang.it')}
                        </span>
                    </React.Fragment>
                );
            case 'fr':
                return (
                    <React.Fragment>
                        <France />
                        <span className="languageSelect__language">
                            {replaceText(texts, 'lang.fr')}
                        </span>
                    </React.Fragment>
                );
            case 'en':
                return (
                    <React.Fragment>
                        <UK />
                        <span className="languageSelect__language">
                            {replaceText(texts, 'lang.en')}
                        </span>
                    </React.Fragment>
                );
            default:
                return 'missing';
        }
    };

    const languages = {};

    SUPPORTED_LANGUAGES.forEach(lang => {
        languages[lang] = getFlag(lang);
    });
    return (
        <div className="languageSelect">
            <div>
                <Dropdown
                    hasFlags={true}
                    selectChild={true}
                    minHeight={20}
                    clickHandler={handleSelect}
                    label={languages[language]}>
                    <LanguageOptions
                        languages={languages}
                        currentLanguage={language}
                    />
                </Dropdown>
            </div>
        </div>
    );
};

// PropTypes for this Component
LanguageSelect.propTypes = {
    language: PropTypes.string,
    texts: PropTypes.any,
    selectLanguageHandler: PropTypes.func,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(LanguageSelect);

// Language Option Element
function LanguageOptions(props) {
    return (
        <div>
            {Object.keys(props.languages).map(language => (
                <div
                    className="languageSelect__option"
                    key={language}
                    onClick={() => {
                        props.clickElement(language);
                    }}>
                    <span>{props.languages[language]}</span>
                    <span>
                        {language === props.currentLanguage && <Tick />}
                    </span>
                </div>
            ))}
        </div>
    );
}

LanguageOptions.propTypes = {
    clickElement: PropTypes.func,
    languages: PropTypes.any,
    currentLanguage: PropTypes.any,
};
