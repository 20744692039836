import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import clsx from 'clsx';
import { replaceText } from '../../helper';

export const CenterPhonebookFormComponent = ({
    name,
    setName,
    role,
    setRole,
    phone,
    setPhone,
    saveEntry,
    nameError,
    phoneError,
    roleValidationError,
    nameValidationError,
    texts,
    children,
}) => {
    return (
        <>
            <h2>{replaceText(texts, 'phonebook.newcontact')}</h2>
            <form onSubmit={saveEntry}>
                <div
                    className={clsx({
                        'has-error': nameError || nameValidationError,
                    })}>
                    <label htmlFor="name">
                        <h3>{replaceText(texts, 'name')}</h3>
                    </label>
                    <div className="phonebook__row">
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            maxLength={100}
                        />
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 100)}
                        </span>
                    </div>
                    <span className="error-message">
                        {nameError &&
                            replaceText(texts, 'phonebook.error.name')}
                        {nameValidationError &&
                            replaceText(texts, 'phonebook.error.invalid')}
                    </span>
                </div>
                <div className={clsx({ 'has-error': roleValidationError })}>
                    <label htmlFor="role">
                        <h3>{replaceText(texts, 'position')}</h3>
                    </label>
                    <div className="phonebook__row">
                        <input
                            id="role"
                            type="text"
                            value={role}
                            onChange={e => setRole(e.target.value)}
                            maxLength={100}
                        />
                        <span className="hint">
                            {replaceText(texts, 'max.chars', 100)}
                        </span>
                    </div>
                    <span className="error-message">
                        {replaceText(texts, 'phonebook.error.invalid')}
                    </span>
                </div>
                <div className={clsx({ 'has-error': phoneError })}>
                    <label htmlFor="phone">
                        <h3>{replaceText(texts, 'phone')}</h3>
                    </label>
                    <div className="phonebook__row">
                        <input
                            id="phone"
                            type="tel"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            maxLength={18}
                        />
                        <span className="hint">
                            {replaceText(texts, 'phonebook.hint.phone')}
                        </span>
                    </div>
                    <span className="error-message">
                        {replaceText(texts, 'phonebook.error.phone')}
                    </span>
                </div>
                <button onClick={saveEntry} className="btn btn--primary">
                    {replaceText(texts, 'action.newcontact')}
                </button>
                {children}
            </form>
        </>
    );
};

// PropTypes for this Component
CenterPhonebookFormComponent.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    role: PropTypes.string,
    setRole: PropTypes.func,
    saveEntry: PropTypes.func,
    nameError: PropTypes.bool,
    phoneError: PropTypes.bool,
    roleValidationError: PropTypes.bool,
    nameValidationError: PropTypes.bool,
    texts: PropTypes.object,
    children: PropTypes.node,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const CenterPhonebookForm = connect(mapStateToProps)(
    CenterPhonebookFormComponent
);
