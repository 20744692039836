import { remapUser, getScopes, getOrganization } from './apiHelper';
import { oauth } from '../OAuth';
import { restCall } from './api';

const userAPIUrl = process.env.REACT_APP_USER_ENDPOINT;

/**
 * get users
 * @returns {object} response data
 */
export const getUsersAPI = async () => {
    return await fetchUserAPI({}, false, true);
};

/**
 * get user
 * @returns {object} response data
 */
export const getUserAPI = async id => {
    return await fetchUserAPI({}, id, true);
};

/**
 * add a new user
 * @param {object} user
 * @returns {object} response data
 */
export const addUserAPI = async user => {
    const remappedUser = remapUser(user);
    remappedUser.organization = getOrganization(user.organization.name);
    const newUser = await fetchUserAPI({
        method: 'POST',
        body: JSON.stringify(remappedUser),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    await updateUserScope({
        id: newUser.id,
        role: user.role,
    });

    return await getUserAPI(newUser.id);
};

/**
 * update an existing user
 * @param {object} user
 * @returns {object} response data
 */
export const updateUserAPI = async user => {
    const remappedUser = remapUser(user);

    await fetchUserAPI(
        {
            method: 'PATCH',
            body: JSON.stringify(remappedUser),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        user.id
    );

    await updateUserOrganization({
        center: user.organization.name,
        id: user.id,
    });

    await updateUserScope({
        id: user.id,
        role: user.role,
    });

    return await getUserAPI(user.id);
};

/**
 * update the organization for a specific user
 * @param {object} data
 * @param {object} data.center
 * @param {string} data.id
 * @returns {object} response data
 */
const updateUserOrganization = async ({ center, id }) => {
    await putOrganizationOrScopeAPI(
        getOrganization(center),
        id,
        'organization'
    );
};

/**
 * update the user scope
 * @param {object} data
 * @param {string} data.role
 * @param {string} data.id
 * @returns {object} response data
 */
const updateUserScope = async ({ role, id }) => {
    await putOrganizationOrScopeAPI(getScopes(role), id, 'scopeList');
};

/**
 * delete a user for the givin id
 * @param {string} id
 * @returns {object} response data
 */
export const deleteUserAPI = async id => {
    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
        method: 'DELETE',
    };

    await restCall(`${userAPIUrl}/${id}`, params, false);
};

/**
 * query the user api to update organization or the scope
 * @param {object} data
 * @param {string} id
 * @param {string} add
 * @returns {object} response data
 */
const putOrganizationOrScopeAPI = async (data, id, add) => {
    const params = {
        withCredentials: true,
        method: 'PUT',
        body: data,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'text/uri-list',
        },
    };
    await restCall(`${userAPIUrl}/${id}/${add}`, params, false);
};

/**
 * query the user api
 * @param {object} params
 * @param {string} id
 * @param {boolean} useProjection
 * @returns {object} response data
 */
const fetchUserAPI = async (params = {}, id = false, useProjection = false) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = userAPIUrl;
    if (id) {
        url = `${url}/${id}`;
    }
    if (useProjection) {
        url = `${url}?projection=adminData`;
    }

    const result = await restCall(url, mergedParams);

    if (!result.page) {
        if (result._embedded) return result._embedded.users;
        return result;
    }

    // get all results if there are more pages
    const limit = result.page.totalElements;
    const usersResults = await restCall(`${url}&limit=${limit}`, mergedParams);

    return usersResults._embedded.users;
};
