import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Tab.scss';

export const Tab = ({ clickHandler, isActive, children }) => {
    const classes = clsx('btn tab', { 'btn--active': isActive });
    return (
        <button className={classes} onClick={clickHandler}>
            {children}
        </button>
    );
};

// PropTypes for this Component
Tab.propTypes = {
    clickHandler: PropTypes.func,
    isActive: PropTypes.bool,
    children: PropTypes.any,
};
