import { oauth } from '../OAuth';

const scopesAPIUrl = process.env.REACT_APP_SCOPES_ENDPOINT;
const passwordAPIUrl = process.env.REACT_APP_PASSWORD_ENDPOINT;
const systemHealthAPIUrl = process.env.REACT_APP_SYSTEMHEALTH_ENDPOINT;
const welcomeMessagesAPIUrl = process.env.REACT_APP_WELCOMEMESSAGES_ENDPOINT;
const applicationsAPIUrl = process.env.REACT_APP_APPLICATIONS_ENDPOINT;
const apiTokenAPI = process.env.REACT_APP_API_TOKEN_ENDPOINT;
const logoutUrl = process.env.REACT_APP_LOGOUT_TOKEN_ENDPOINT;

/**
 * redirect to login page
 */
export const forceLogout = () => {
    window.location.href = '/login';
};

/**
 * handles the rest call and returns the response
 * @param {string} url
 * @param {object} params
 * @param {boolean} isJson json request data
 *
 * @returns {object} response data - json or text
 */
export const restCall = async (url, params, isJson = true) => {
    try {
        const response = await fetchErrorHandling(url, params);
        if (isJson) {
            const json = await response.json();
            return json;
        } else {
            return response;
        }
    } catch (e) {
        if (e.message !== '401' && e.message !== '404') {
            window.location = `/error/${e.name}/${e.message}`;
        }
    }
};

/**
 * get system health
 * @return {object} - response data
 */
export const getSystemHealth = async () => {
    return await fetchSystemHealthAPI();
};

/**
 * update system health
 * @param {object} health - system health
 * @param {string} health.text
 * @param {string} health.status
 * @param {string} health.id
 * @return {object} - response data
 */
export const updateSystemHealth = async ({ text, status, id }) => {
    return await fetchSystemHealthAPI(
        {
            method: id ? 'PUT' : 'POST',
            body: JSON.stringify({
                text,
                status,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        id
    );
};

/**
 * query system health api
 * @param {object} params - request parameters
 * @param {string} id
 * @return {object} - response data
 */

const fetchSystemHealthAPI = async (params = {}, id = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = systemHealthAPIUrl;
    if (id) {
        url = `${url}/${id}`;
    }

    const result = await restCall(url, mergedParams);

    if (!result.page && !result._embedded) return result;
    return result._embedded.systemHealths[0];
};

/**
 * query welcome messages
 * @return {object} - response data
 */
export const getWelcomeMessage = async () => {
    return await fetchWelcomeMessage();
};

/**
 * update welcome messages
 * @param {object} message - welcome message
 * @param {boolean} message.active
 * @param {string} message.headline
 * @param {string} message.text
 * @param {string} message.messageType
 * @param {number} message.order
 * @param {string} message.id
 * @return {object} - response data
 */
export const updateWelcomeMessage = async ({
    active,
    headline,
    text,
    messageType,
    order,
    id,
}) => {
    return await fetchWelcomeMessage(
        {
            method: id ? 'PUT' : 'POST',
            body: JSON.stringify({
                active,
                headline,
                text,
                messageType,
                order,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        },
        id
    );
};

/**
 * query welcome message api
 * @param {object} params
 * @param {string} id
 * @returns {object} response data
 */
const fetchWelcomeMessage = async (params = {}, id = null) => {
    const mergedParams = {
        withCredentials: true,
        ...params,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            ...params.headers,
        },
    };

    let url = welcomeMessagesAPIUrl;
    if (id) {
        url = `${url}/${id}`;
    }

    const result = await restCall(url, mergedParams);

    if (!result.page && !result._embedded) return result;
    return result._embedded.welcomeMessages;
};

/**
 * gets the hash for a given password
 * @param {string} password
 * @returns {object} hash
 */
export const getPasswordHash = async password => {
    const response = await fetch(`${passwordAPIUrl}`, {
        method: 'POST',
        withCredentials: true,
        body: JSON.stringify({ password }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    });
    return await response.text();
};

/**
 * get the user scopes
 * @returns {object} scopes
 */
export const getScopesAPI = async () => {
    const scopes = await restCall(`${scopesAPIUrl}`, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    });

    return scopes._embedded.scopes;
};

/**
 * get the applications data
 * @returns {object} applications
 */
export const getApplicationsAPI = async () => {
    const applications = await restCall(`${applicationsAPIUrl}`, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    });

    return applications._embedded.applications;
};

/**
 * fetch wrapper for error handling
 * throws errors for HTTP 500 and 403
 * @param {string} url
 * @param {object} params
 * @returns {object} response data
 */
const fetchErrorHandling = async (url, params = {}) => {
    return await fetch(url, params).then(response => {
        if (!response.ok) {
            let error = new Error('API ERROR');

            if (response.status === 401) {
                error = new Error('401');
                forceLogout();
            }

            if (response.status === 404) {
                error = new Error('404');
            }

            if (response.status === 403) {
                error = new Error('403');
                return response;
            }

            throw error;
        }
        return response;
    });
};

/**
 * generate an api user
 * @returns {object} response data
 */
export const generateAPIUser = async () => {
    return await restCall(`${apiTokenAPI}`, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    });
};

/**
 * gets the hash for a given password
 * @param {string} password
 * @returns {object} hash
 */
export const postLogout = async () => {
    const response = await fetch(`${logoutUrl}`, {
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oauth.bearerToken}`,
        },
    });
    return await response.text();
};
