import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    updateUserDispatch,
    removeUserDispatch,
} from '../../redux/actions/users';

import Edit from '../icons/Edit';
import Toggle from '../icons/Toggle';
import Delete from '../icons/Delete';

import { userProptypes } from '../../redux/reducers/users';

import '../global/ListEntry.scss';
import { replaceText } from '../../helper';

/**
 * User list entry with options to manipulate.
 */

class UserListEntry extends PureComponent {
    edit = id => {
        this.props.history.push(`/edit/user/${id}`);
    };

    toggleState = id => {
        updateUserDispatch({
            ...this.props.user,
            activated: !this.props.user.activated,
        });
    };

    delete = id => {
        const { name, email } = this.props.user;
        if (
            window.confirm(replaceText(this.props.texts, 'user.prompt', name))
        ) {
            removeUserDispatch(id, email);
        }
    };

    render() {
        const {
            name,
            email,
            organization,
            activated,
            ipRestriction,
            role,
            id,
        } = this.props.user;
        const currentRole = this.props.currentRole;

        const userClass = clsx(
            'col col--name',
            activated ? 'col col--name--green' : 'col col--name--red'
        );

        return (
            <div className="userListEntry">
                <div className={userClass} title={name}>
                    {name}
                </div>
                <div className="col col--email" title={email}>
                    {email}
                </div>
                <div className="col col--ipRestriction" title={ipRestriction}>
                    {ipRestriction}
                </div>
                {currentRole === 'superadmin' ? (
                    <div
                        className="col col--dispatchCenter"
                        title={organization.name}>
                        {organization.name}
                    </div>
                ) : (
                    ''
                )}
                <div className="col col--role" title={role}>
                    {replaceText(this.props.texts, `role.${role}`)}
                </div>
                <div className="col col--options userListEntry__buttons">
                    <button onClick={() => this.edit(id)}>
                        <Edit />{' '}
                        <span>
                            {replaceText(this.props.texts, 'action.edit')}
                        </span>
                    </button>
                    <button
                        onClick={() => this.toggleState(id)}
                        className={activated ? 'deactivate' : 'activate'}>
                        <Toggle />{' '}
                        <span>
                            {activated
                                ? replaceText(
                                      this.props.texts,
                                      'action.deactivate'
                                  )
                                : replaceText(
                                      this.props.texts,
                                      'action.activate'
                                  )}
                        </span>
                    </button>
                    <button onClick={() => this.delete(id)}>
                        <Delete />
                        <span>
                            {replaceText(this.props.texts, 'action.delete')}
                        </span>
                    </button>
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
UserListEntry.propTypes = {
    user: PropTypes.shape(userProptypes),
    currentRole: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    texts: PropTypes.object,
    // users: PropTypes.arrayOf(userProptypes),
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentRole: state.currentUser.role,
        texts: state.texts.texts,
        // users: state.users,
    };
};

// Connect Props to Component
export default withRouter(connect(mapStateToProps)(UserListEntry));
