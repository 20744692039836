import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './MessageBlock.scss';
import { messageBlockProptypes } from '../../redux/reducers/messages';
import clsx from 'clsx';
import { saveSystemMessageDispatch } from '../../redux/actions';
import { replaceText } from '../../helper';

/**
 * Form for the message blocks visible in the frontend.
 */
class MessageBlock extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            headline: '',
            text: '',
            messageType: 'INFO',
            saveState: 'START',
        };

        this.headlineRef = React.createRef();
        this.textRef = React.createRef();
    }

    changeHeadline = () => {
        this.setState({ headline: this.headlineRef.current.value });
    };

    changeText = () => {
        this.setState({ text: this.textRef.current.value });
    };

    changeRadio = e => {
        const messageType = e.target.value;
        this.setState({ messageType });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.save();
    };

    save = async () => {
        this.setState({ saveState: 'SAVING' });
        await saveSystemMessageDispatch({
            digit: this.props.elementDigit,
            messageBlock: this.state,
        })
            .then(() => {
                this.setState({ saveState: 'SAVED' });
            })
            .catch(e => {});
    };

    toggleStatus = () => {
        this.setState({ active: !this.state.active }, this.save);
    };

    componentDidMount() {
        this.setState({ ...this.props.messageBlock });
    }

    render() {
        const digit = this.props.elementDigit;
        return (
            <form
                autoComplete="off"
                className="messageBlock"
                onSubmit={this.handleSubmit}>
                <div>
                    <p>
                        <span
                            className={clsx({
                                'is-active': this.state.active,
                            })}>
                            {this.state.active
                                ? replaceText(
                                      this.props.texts,
                                      'textblock.active.status'
                                  )
                                : replaceText(
                                      this.props.texts,
                                      'textblock.inactive.status'
                                  )}
                        </span>
                    </p>
                </div>
                <div>
                    <div>
                        <label htmlFor={`m_headline_${digit}`}>
                            <h3>{replaceText(this.props.texts, 'headline')}</h3>
                        </label>
                        <input
                            maxLength={80}
                            type="text"
                            id={`m_headline_${digit}`}
                            placeholder={replaceText(
                                this.props.texts,
                                'message.headline.placeholder'
                            )}
                            defaultValue={this.state.headline}
                            onChange={this.changeHeadline}
                            ref={this.headlineRef}
                        />
                        <div className="messageBlock__hint">
                            <span>
                                {replaceText(this.props.texts, 'max.chars', 80)}
                            </span>
                        </div>
                    </div>
                    <div>
                        <label htmlFor={`m_text_${digit}`}>
                            <h3>{replaceText(this.props.texts, 'text')}</h3>
                        </label>
                        <textarea
                            maxLength={255}
                            id={`m_text_${digit}`}
                            placeholder={replaceText(
                                this.props.texts,
                                'message.text.placeholder'
                            )}
                            defaultValue={this.state.text}
                            onChange={this.changeText}
                            ref={this.textRef}
                        />
                        <div className="messageBlock__hint">
                            <span>
                                {replaceText(
                                    this.props.texts,
                                    'max.chars',
                                    255
                                )}
                            </span>
                            <span>
                                {replaceText(this.props.texts, 'message.hint')}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <h3>{replaceText(this.props.texts, 'message.type')}</h3>
                        <p>
                            <input
                                checked={this.state.messageType === 'ERROR'}
                                value="ERROR"
                                type="radio"
                                name="messageType"
                                id={`r_error_${digit}`}
                                onChange={this.changeRadio}
                            />
                            <label htmlFor={`r_error_${digit}`}>
                                <span className="alert alert--error">
                                    {replaceText(
                                        this.props.texts,
                                        'type.error'
                                    )}
                                </span>
                            </label>
                        </p>
                        <p>
                            <input
                                checked={this.state.messageType === 'WARNING'}
                                value="WARNING"
                                type="radio"
                                name="messageType"
                                id={`r_warning_${digit}`}
                                onChange={this.changeRadio}
                            />
                            <label htmlFor={`r_warning_${digit}`}>
                                <span className="alert alert--warning">
                                    {replaceText(
                                        this.props.texts,
                                        'type.warning'
                                    )}
                                </span>
                            </label>
                        </p>
                        <p>
                            <input
                                checked={this.state.messageType === 'INFO'}
                                value="INFO"
                                type="radio"
                                name="messageType"
                                id={`r_hint_${digit}`}
                                onChange={this.changeRadio}
                            />
                            <label htmlFor={`r_hint_${digit}`}>
                                <span className="alert alert--hint">
                                    {replaceText(this.props.texts, 'type.hint')}
                                </span>
                            </label>
                        </p>
                    </div>
                    <div>
                        <div>
                            {this.state.saveState === 'SAVING' && (
                                <div className="messageBlock__save">
                                    {replaceText(this.props.texts, 'saving')}
                                </div>
                            )}
                            {this.state.saveState === 'SAVED' && (
                                <div className="messageBlock__save">
                                    {replaceText(this.props.texts, 'saved')}
                                </div>
                            )}
                            <div>
                                <button
                                    className="btn btn--primary"
                                    disabled={this.state.saveState === 'SAVING'}
                                    onClick={this.toggleStatus}>
                                    {this.state.active
                                        ? replaceText(
                                              this.props.texts,
                                              'action.deactivate'
                                          )
                                        : replaceText(
                                              this.props.texts,
                                              'action.activate'
                                          )}
                                </button>
                                <button
                                    className="btn btn--primary"
                                    disabled={this.state.saveState === 'SAVING'}
                                    type="submit">
                                    {replaceText(
                                        this.props.texts,
                                        'action.save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

// PropTypes for this Component
MessageBlock.propTypes = {
    elementDigit: PropTypes.number,
    messageBlock: PropTypes.shape(messageBlockProptypes),
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = (state, ownProps) => {
    const { elementDigit } = ownProps;
    return {
        messageBlock: state.messages.messageBlocks[elementDigit],
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(MessageBlock);
