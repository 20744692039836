import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MAX_UPLOAD_FILE_SIZE } from '../../config';
import Loading from '../global/Loading';
import './UploadForm.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { replaceText } from '../../helper';
import CenterConferenceImage from './CenterConferenceImage';
import { uploadFileConference } from '../../api/conferenceAPI';

const UploadFormConferenceComponent = ({
    match,
    currentUserOrganization,
    currentRole,
    centers,
    texts,
}) => {
    const fileRef = useRef();
    const [loading, setLoading] = useState(false);
    const [isTooBig, setIsTooBig] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isWrongFormat, setIsWrongFormat] = useState(false);

    const startUpload = async e => {
        if (isTooBig || isWrongFormat || isEmpty) return;
        e.preventDefault();
        const file = fileRef.current.files[0];
        const centerId = isSuperadmin ? currentCenter.addOnId : null;

        setLoading(true);

        await uploadFileConference({ file, centerId });

        setIsWrongFormat(false);
        setIsTooBig(false);
        setIsEmpty(true);
        setLoading(false);
    };

    const validate = () => {
        if (fileRef.current && fileRef.current.files.length === 1) {
            setIsEmpty(false);
        } else {
            setIsEmpty(true);
        }
        if (
            fileRef.current &&
            fileRef.current.files.length === 1 &&
            fileRef.current.files[0].size >= MAX_UPLOAD_FILE_SIZE
        ) {
            setIsTooBig(true);
        } else {
            setIsTooBig(false);
        }
        if (
            fileRef.current &&
            fileRef.current.files.length === 1 &&
            fileRef.current.files[0].type !== 'image/jpeg' &&
            fileRef.current.files[0].type !== 'image/png'
        ) {
            setIsWrongFormat(true);
        } else {
            setIsWrongFormat(false);
        }
    };

    const userOrgId = currentUserOrganization.id;
    const orgId = parseInt(match.params.id);
    const isSuperadmin = currentRole === 'superadmin';
    const currentCenter = centers.filter(center => center.id === orgId)[0];

    if (userOrgId === orgId || isSuperadmin) {
        return (
            <div className="upload-form">
                {loading ? (
                    <Loading text={replaceText(texts, 'logo.uploading')} />
                ) : (
                    <React.Fragment>
                        <CenterConferenceImage
                            id={isSuperadmin ? currentCenter.addOnId : null}
                        />
                        <div className="upload-form__form">
                            <input
                                type="file"
                                name="file"
                                accept="image/png, image/jpeg"
                                ref={fileRef}
                                onChange={validate}
                            />

                            <div className="upload-form__button-wrapper">
                                <button
                                    className="btn btn--primary"
                                    type="submit"
                                    value="Upload Files"
                                    name="submit"
                                    disabled={
                                        isWrongFormat || isTooBig || isEmpty
                                    }
                                    onClick={startUpload}>
                                    {replaceText(texts, 'logo.upload')}
                                </button>
                                {isTooBig && (
                                    <span className="upload-form__error">
                                        {replaceText(texts, 'logo.error.size')}
                                    </span>
                                )}
                                {isWrongFormat && (
                                    <span className="upload-form__error">
                                        {replaceText(
                                            texts,
                                            'logo.error.format'
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    } else {
        return (
            <div className="upload-form">
                <span className="upload-form__hint">
                    {replaceText(texts, 'logo.only')}
                </span>
            </div>
        );
    }
};

// PropTypes for this Component
UploadFormConferenceComponent.propTypes = {
    currentUserOrganization: PropTypes.any,
    currentRole: PropTypes.string,
    match: PropTypes.any,
    centers: PropTypes.any,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        currentUserOrganization: state.currentUser.organization,
        currentRole: state.currentUser.role,
        centers: state.dispatchCenters.centers,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const UploadFormConference = withRouter(
    connect(mapStateToProps)(UploadFormConferenceComponent)
);
