import packageInformation from '../package.json';

// Default role mapping
export const roles = {
    none: { scope: '' },
    user: { scope: '' },
    admin: { scope: 'dispatch.admin' },
    superadmin: { scope: 'data.admin' },
    api: { scope: 'api.access' },
};

// Default scopes that are always set
export const defaultScopes = [
    {
        name: 'emeyex.invite',
    },
    {
        name: 'emeyex.sendsms',
    },
    {
        name: 'emeyex.login',
    },
];

// eslint-disable-next-line
export const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

// toggle debugging via env file
export const DEBUG = process.env.REACT_APP_DEBUG === 'true';

// set the version via package.json
export const VERSION = packageInformation.version;

// Default features and labels
export const defaultFeatures = [
    'PHOTO',
    'HD_SEND',
    'GEOSMS',
    'CURSOR',
    'CASE_DOWNLOAD',
    'MULTILANGUAGE',
    'LOGO_UPLOAD',
    'PHONEBOOK',
    'SMS_GUIDE',
    'DRAW',
    'AUDIO_STREAM',
    'INVITE',
    'BIDI',
    'NOTES',
    'ORG_STRUCTURE',
];

// Force https for api urls via env file
export const FORCE_HTTPS = process.env.REACT_APP_FORCE_HTTPS === 'true';

// MAX FILE SIZE
export const MAX_UPLOAD_FILE_SIZE = 2097152; // 2 MB in byte (binary);

// RTE TOOLBAR
export const RTE_TOOLBAR = {
    display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'LINK_BUTTONS',
        'HISTORY_BUTTONS',
    ],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
    ],
};

// LANGUAGES
export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE;

export const SUPPORTED_LANGUAGES = ['de', 'fr', 'it', 'en'];

// default mimetype
export const DEFAULT_MIMETYPE = 'PNG';

// configurable timeout
export const DEFAULT_TIMEOUT = 3000;

// max length organigram label input
export const DEFAULT_MAX_DISPLAY_LENGTH = 20;
