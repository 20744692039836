import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { oauth, authenticate } from '../OAuth';

import { getUsersDispatch } from '../redux/actions/users';
import { userProptypes } from '../redux/reducers/users';
import {
    setCurrentUserDispatch,
    getUserScopesDispatch,
    getApplicationsDispatch,
    getApplicationConfigsDispatch,
    getInviteConfigsDispatch,
    getSessionReportConfigsDispatch,
} from '../redux/actions';
import { getDispatchCentersDispatch } from '../redux/actions/dispatchCenters';

import Header from './global/Header';
import { replaceText } from '../helper';
import { postUserConfigIfNoneAssigned } from '../api/apiHelper';

/**
 * Handles OAuth authentification and loads the relevant data. Application entry point after login.
 */

class OAuth extends PureComponent {
    async componentDidMount() {
        await authenticate();

        if (oauth.bearerToken) {
            getUsersDispatch();
            getDispatchCentersDispatch();
            getUserScopesDispatch();
            getApplicationsDispatch();
            getApplicationConfigsDispatch();
            getInviteConfigsDispatch();
            getSessionReportConfigsDispatch();
            postUserConfigIfNoneAssigned();
        }
    }

    componentDidUpdate() {
        if (oauth.bearerToken && this.props.users.length > 0) {
            console.log('users', this.props.users);
            const currentUser = this.props.users.filter(user => {
                return (
                    user.email.toLowerCase() === oauth.userName.toLowerCase()
                );
            })[0];
            setCurrentUserDispatch(currentUser);
            this.props.history.push('/user');
        }
    }

    render() {
        return (
            <div className="oauth">
                <Header />
                <div className="container">
                    {replaceText(this.props.texts, 'loading.app')}
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
OAuth.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape(userProptypes)),
    history: PropTypes.any,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        users: state.users,
        texts: state.texts.texts,
    };
};

// Connect Props to Component
export default withRouter(connect(mapStateToProps)(OAuth));
